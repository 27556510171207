import React from "react";
import Lottie from "react-lottie";
import loaderAnimation from "assets/loader.json";
import {pageloader} from "assets/images/index"

const AppLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
  };

  return (
    <div className="flex items-center ">
      <div className="w-1/4 h-1/4">
      <img src={pageloader} alt="We are processing. Please wait" /> 
        {/* <Lottie options={defaultOptions} /> */}
      </div>
    </div>
  );
};

export default AppLoader;
