import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes"; 
import './assets/css/main.css'
import './assets/css/responsive.css'
import './assets/css/bootstrap.min.css'
import './assets/css/font/font-style.css'

function App() {
  return <BrowserRouter>{AppRoutes}</BrowserRouter>;
}

export default App;
