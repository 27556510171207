import React, { useEffect, useState,useRef } from "react";  
import { useNavigate,useParams } from "react-router-dom";  
import { logo } from "assets/images"; 

const Home = () => {
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const input_receipeName = useRef(undefined);  
  const input_NumberOfServing = useRef(undefined);   
  const navigate = useNavigate();
 

  const submit = (element) => {
    element.preventDefault(); 
    setLoginBtnLoading(false);
    console.log(input_receipeName.current.value)
    const formattedrecipeName = input_receipeName.current.value.replace(" ","_");
    console.log(formattedrecipeName)
    navigate("/recipedetails/"+formattedrecipeName+"/"+input_NumberOfServing.current.value)
  };

  return (
    <> 
        {/* <nav className="navbar navbar-expand-lg navbar-dark d-none d-lg-block" style={{zIndex: "2000"}}>
            <div className="container-fluid"> 
                <a className="navbar-brand nav-link" target="_blank" href="https://mdbootstrap.com/docs/standard/">
                    <strong>LOGO</strong>
                </a>
            </div>
        </nav>  */}
        <div id="intro" className="bg-image">
            <div className="container d-flex align-items-center h-100">
                <div className="row w-100">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="mb-5 text-white banner-title">Search Recipe</h1>
                            </div>
                            <form className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <input className="form-control"  ref={input_receipeName}  required placeholder="Recipe name" />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="number" className="form-control"  ref={input_NumberOfServing}  required placeholder="No of serving" />
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" className="default-btn recipe-search" id="route1" onClick={submit}><i
                                                className="ri-search-eye-line me-2"></i>SEARCH</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  );
};

export default Home;
