import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { client, ApolloProvider } from "./apollo-client";
import { StyledEngineProvider } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Montserrat, sans-serif",
      textTransform: "none",
      letterSpacing: "0px",
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StyledEngineProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
