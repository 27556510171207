import React from "react";
import { Button } from "./Button";
import { AddButton, SubtractButton } from "./AddSubtractButton";
import { P } from "./P";
import { lightGray } from "./GlobalStyles";
import styled from "styled-components";

export const ListedItems = ({
  items,
  increaseCount,
  decreaseCount,
  addToCart
}) => ( 
  <>
  {items.map((item, i) => (
  <div className="col-md-6">
  <div className="product-list" key={item.product_name}>  
          <div className="image">
              <a>
                <img src={ item.src }  alt={item.product_name}
                      className="img-responsive center-block" />
              </a>
          </div>
          <div className="caption">
              <h5> { item.product_name }</h5>
              <p className="price">
                  <span className="price"> { item.weight }</span>
              </p>
              <div className="product-button-group">
                  <div className="quantity">
                      <div className="quantity-button minus">
                        <AddButton onClick={() => increaseCount(i)} /></div> 
                          <span className="qty" ><p>{item.productquantity }</p></span>
                      <div className="quantity-button plus">
                      <SubtractButton onClick={() => decreaseCount(i)} /></div>
                            </div>
                  {!item.inCart && (
                    <Button onClick={() => addToCart(i)}>Add to Cart</Button>
                  )}
                  {item.inCart && <P>Added!</P>}</div>
               </div>
  </div>
       
  </div> 
    ))} </>  
); 
