import { Routes, Route } from "react-router-dom";
import Home from "pages/home"; 
import RecipeDetails from "pages/recipedetails";  
import PrivateRoute from "./private-route";
import NotFound from "components/common/not-found";

export const AppRoutes = (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} /> 
    <Route exact path="/recipedetails/:recipename/:noofserving" element={<RecipeDetails />} />
    {/* <Route exact path="/" element={<PrivateRoute />}>
      <Route path="/" element={<TeacherDashboard />} />
    </Route>
    <Route path="/course/session/:id" element={<PrivateRoute />}>
      <Route path="/course/session/:id" element={<SessionAttendance />} />
    </Route> */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);
