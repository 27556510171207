import React, { useEffect, useState } from "react";
import { gql, useMutation,useQuery,useLazyQuery } from "@apollo/client";  
import { useNavigate,useParams } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';  
import AppLoader from "components/common/app-loader";
import {ingredientimg,directionimg} from "../assets/images/index" 
import styled from "styled-components"; 
import { ListedItems } from "../components/common/ListedItems";
import { FixedCart } from "../components/common/FixedCart";
import { CartDetails } from "../components/common/CartDetails";
import { Overlay } from "../components/common/Overlay";  


const GET_RECEIPE_DETAILS = gql`query getRecipeBoxDetails($getRecipeBoxRecipe: RecipeBoxInput!) {
  getRecipeBox(recipe: $getRecipeBoxRecipe) {
    success
    recipe_box {
      ingredients {
        name
        weight
        quantity
      }
      servings
      name
      store {
        product_name
        quantity
        image
      }
      nutrition
    }
    code
    message
  }
}
`;
const RecipeDetails = () => {    
  const initialnutrition = { 
    calories : "",
    protein : "",  
    fat : "",  
    carbohydrates : "",  
    sodium : "",  
    fiber : "",  
    sugar : ""
  }
  const navigate = useNavigate();
  let { recipename,noofserving } = useParams();  
  const formattedrecipeName = recipename.replace("_"," ");  
  const [ingredients,setingredients] = useState([]); 
  const [showpopup, setshowpopup] = useState(false); 
  const [AlertMessge, setAlertMessge] = useState("");  
  const [items, setItems] = useState([]);
  const [nutrition,setnutrition] = useState(initialnutrition)  ; 
  const [cart, setCart] = useState([]); 
  const [cartOpen, isCartOpen] = useState(false);

  const {
    loading: recipeDataLoading,
    error: recipeDataError,
    data: recipeData} = useQuery(GET_RECEIPE_DETAILS, {  
      variables: { 
        "getRecipeBoxRecipe": {
          "no_of_servings": Number(noofserving),
          "name": formattedrecipeName
        } 
      },
    });
 
    
  useEffect(() => {  
    fetchdetails();
  }, [recipeData]);
   
  
function formatarray(arg){
  const formatarg = [];
  arg.forEach(element  => {
    const argobj = {  
      product_name: element.product_name,
      weight: element.quantity,
      productquantity: 1,
      inCart: false, 
      src:element.image[0] 
    };
    formatarg.push(argobj);
  });  
  setItems([...formatarg,...items.map(value=>value.store)]); 
  }
  function fetchdetails(){  
    setingredients([])
    setItems([])
    setnutrition(initialnutrition)
    console.log("all")
    console.log(recipeData);
    if (
      recipeData?.getRecipeBox?.code === "000" &&
      recipeData?.getRecipeBox?.success
    ) { 
      let recipe_box = recipeData?.getRecipeBox?.recipe_box; 
      console.log(recipe_box)
      if (recipe_box) { 
        let ingredientsarr = recipeData?.getRecipeBox?.recipe_box?.ingredients;  
        if (ingredientsarr) { 
          setingredients([...ingredientsarr,...ingredients.map(value=>value.ingredients)]);    
        }  
        let storearr = recipeData?.getRecipeBox?.recipe_box?.store;  
        if (storearr) {  
          formatarray(storearr);
          console.log(...items);    
        }  
        let nutritionarr = recipeData?.getRecipeBox?.recipe_box?.nutrition;  
        if (nutritionarr) { 
          setnutrition(nutritionarr);    
        }  
      } 
    }    

    if (recipeDataError) {
      console.log("Recipe Error->:", recipeDataError);
      setshowpopup(true);
      setAlertMessge("Please check the recipe name or try different recipe.")
    }  
  } 
  function PageRefresh(){ 
    setshowpopup(false);
    window.location.reload();
  }
  function ToHome(){ 
    navigate("/");
  }

  const addToCart = i => {
    setItems(state =>
      state.map((item, p) => {
        if (i === p) {
          setCart([
            ...cart,
            { product_name: item.product_name, price: 10, productquantity: item.productquantity }
          ]);
          return { ...item, inCart: true };
        }
        return item;
      })
    );
  };

  const increaseQuantity = {
    inCart: i => {
      setCart(state =>
        state.map((item, o) => {
          if (i === o  && item.productquantity < 10 ) {
            return { ...item, productquantity: item.productquantity + 1 };
          }
          return item;
        })
      );
    },
    inItems: i => {
      setItems(state =>
        state.map((item, o) => {
          if (o === i && item.productquantity < 10) {
            return { ...item, productquantity: item.productquantity + 1 };
          }
          return item;
        })
      );
    }
  };

  const decreaseQuantity = {
    inCart: i => {
      setCart(prevCart =>
        prevCart.map((item, o) => {
          if (i === o && item.productquantity > 1) {
            return { ...item, productquantity: item.productquantity - 1 };
          }
          return item;
        })
      );
    },
    inItems: i => {
      setItems(state =>
        state.map((item, o) => {
          if (i === o && item.productquantity > 1) {
            return { ...item, productquantity: item.productquantity - 1 };
          }
          return item;
        })
      );
    }
  };

  const removeFromCart = i => {
    let chosenItem, index;
    index = 0;
    while (index < cart.length) {
      if (index === i) {
        chosenItem = cart[index].product_name;
        break;
      }
      index++;
    }
    setCart(state => state.filter(item => chosenItem !== item.product_name));
    setItems(state =>
      state.map(item => {
        if (item.product_name === chosenItem) {
          return { ...item, inCart: false, productquantity: 1 };
        }
        return item;
      })
    );
  };

  const onCheckout = () =>{  
    isCartOpen(false);
    setAlertMessge("Thanks!!! The presentation was effective.");
    setshowpopup(true);
  };
  const cartCountTotal = cart.reduce((acc, item) => acc + item.productquantity, 0);

  return (
    <>   
      <CartDetails
        open={cartOpen}
        onClose={() => isCartOpen(false)}
        cart={cart}
        increaseQ={increaseQuantity.inCart}
        decreaseQ={decreaseQuantity.inCart}
        cartCountTotal={cartCountTotal}
        removeFromCart={removeFromCart}
        onCheckout={onCheckout}
      />

      <FixedCart onOpen={() => isCartOpen(true)} cartItems={cartCountTotal} />
      <Overlay onClick={() => isCartOpen(false)} open={cartOpen} />

    <header> 
        <div className="header-title-breadcrumb relative">
            <div className="header-title-section recipe-overlay text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                            <h1 className="text-white banner-title">Recipe: {formattedrecipeName}</h1>
                            {/* <ol className="breadcrumb text-center">
                                <li><a href="/home">Home</a></li>
                                <li className="active"></li>
                            </ol> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header> 
    <Popup open={showpopup} className='langpopup'
        modal nested>
        { 
          //close =>{ 
            <div className='modal'>
                <div className='content'>
                    <div className='modaltitle'>
                      <p>Alert</p>
                    </div>
                    <div className='modalContent'>
                      <p>{AlertMessge}</p>
                    </div>
                </div>
                <div className='content-btn'>
                    <button onClick=
                        {() => PageRefresh()}>
                            Try Again
                    </button>
                    <button onClick=
                        {() => ToHome()}>
                            Home
                    </button>
                </div>
            </div> 
          //}
        }
    </Popup> 
    <div className="container">
        
        <div className="high-padding-top">
            <div className="tab-content">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>{formattedrecipeName}</h2>
                        <ul className="recipe-info content-full"> 
                            <li className="yield">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"
                                    strokeWidth="1.5" stroke="#E4E1DB" fill="none" strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3">
                                    </path>
                                </svg>
                                <ul>
                                    <li><i>servings </i></li>
                                    <li className="value">{noofserving}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tab-content">
                <div className="row">
                  <div className="col-md-6">
                  <div className="col-md-12 mb-2">
                        <h4 className="tab-title">
                          <img width="35" className="" src={ingredientimg} alt="ingredient-image" /> <span>Ingredients</span></h4>
                    </div> 
                    {recipeDataLoading && <AppLoader />} 
                    {!recipeDataLoading && !recipeDataError && (
                      <div className="col-md-12">  
                        <ul>
                            {ingredients.map((row) => (     
                              <>
                              <li> { row.name } {row.weight}</li>
                              </> 
                            ))}  
                        </ul> 
                      </div>
                    )}  
                  </div>
                  <div className="col-md-6"> 
                  <div className="col-md-12 mb-2">
                        <h4 className="tab-title">
                          <img width="35" className="" src={directionimg} alt="ingredient-image" /> <span>Nutrition Details</span></h4>
                    </div> 
                    {recipeDataLoading && <AppLoader />}
                    {!recipeDataLoading && !recipeDataError && (
                      <div className="col-md-12">  
                        <ul> 
                           <li>calories <span>{nutrition.calories}</span></li> 
                           <li>protein  <span>{nutrition.protein}</span> </li> 
                           <li>fat   <span>{nutrition.fat}</span></li> 
                           <li>carbohydrates  <span>{nutrition.carbohydrates}</span> </li> 
                           <li>sodium   <span>{nutrition.sodium}</span></li> 
                           <li>fiber   <span>{nutrition.fiber}</span></li> 
                           <li>sugar   <span>{nutrition.sugar}</span></li> 
                        </ul> 
                      </div>
                    )}     
                  </div> 
            </div> 
        </div>
    </div>
    <div className="container"> 
            <div className="tab-content">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="product-title">
                            <span>Ingredients From Our Store</span>
                        </h4>
                    </div>
                    {recipeDataLoading && <AppLoader />} 
                    {!recipeDataLoading && !recipeDataError && (
                    <ListedItems
                      items={items}
                      increaseCount={increaseQuantity.inItems}
                      decreaseCount={decreaseQuantity.inItems}
                      addToCart={addToCart}
                    />  
                    )}
                </div>
            </div>   
    </div></div>
    </>
  ); 
}; 

export default RecipeDetails;
