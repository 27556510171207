import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const handleGoToHomepage = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-4xl font-semibold text-[#044AA0] mb-4">404</h2>
        <p className="text-gray-600 mb-4">Page not found</p>
        <p className="text-gray-500">The requested page does not exist.</p>
        <div className="mt-6">
          <Button
            className="px-4 py-2 bg-[#044AA0] text-white rounded hover:bg-[#044AA0] focus:outline-none focus:bg-[#044AA0]"
            onClick={handleGoToHomepage}
          >
            Go to Homepage
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
