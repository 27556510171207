import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  ApolloLink,
  concat,
} from "@apollo/client"; 
import ApolloLinkTimeout from 'apollo-link-timeout'


const timeoutLink = new ApolloLinkTimeout(30000)
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL })
const timeoutHttpLink = timeoutLink.concat(httpLink)
console.log(process.env.REACT_APP_GRAPHQL_API_URL); 
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export { client, ApolloProvider };
